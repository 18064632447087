import React from "react"
import { Disclosure, Menu, Transition } from "@headlessui/react"
import { Image } from "cloudinary-react"
import { MenuIcon, XIcon } from "@heroicons/react/outline"
import { useMsal } from "@azure/msal-react"

import { Link } from "gatsby"

const NavBarSignedIn = () => {
  const { instance } = useMsal()
  return (
    <Disclosure as="nav" className="bg-white shadow sticky top-0 z-50">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center pt-0">
                  <Link to="/">
                    <Image
                      cloudName={process.env.CLOUDINARY_CLOUD_NAME}
                      publicId="v1624539199/Kunder/Logoer/DLF/dlf_logo.png"
                      alt="DLF Logo"
                      // loading="lazy"
                      className="h-16 w-auto"
                      secure
                    />
                  </Link>
                </div>
              </div>
              <div className="hidden md:ml-6 md:flex md:space-x-4 nav-links show-links">
                {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                <Link
                  to="/"
                  className="nav-link border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                  activeClassName="border-link-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                >
                  Profil
                </Link>
                <Link
                  to="/endreBruker/"
                  className="nav-link border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                  activeClassName="border-link-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                >
                  Rediger
                </Link>
                <a
                  href="https://www.dlf.no/medlemssider/"
                  target="_blank"
                  className="nav-link border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                >
                  Logg inn medlemssider
                </a>
                <a
                  href="mailto: firmapost@dlf.no"
                  target="_blank"
                  className="nav-link border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                >
                  Kontakt DLF
                </a>
              </div>
              <div className="hidden md:ml-3 md:flex md:items-center">
                <div className="flex items-center ">
                  <Link
                    to="/"
                    className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-link-500 hover:bg-link-400"
                    activeClassName="active-link"
                    onClick={() => instance.logout()}
                  >
                    Logg ut
                  </Link>
                </div>
              </div>

              <div className="-mr-2 flex items-center md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-link-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="">
            <div className="pt-2 pb-3 space-y-1">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
              <Link
                to="/"
                className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                activeClassName="bg-logo-50 border-link-500 text-logo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
              >
                Profil
              </Link>
              <Link
                to="/endreBruker/"
                className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                activeClassName="bg-logo-50 border-link-500 text-logo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
              >
                Rediger
              </Link>
              <Link
                to="/"
                className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                // activeClassName="bg-logo-50 border-link-500 text-logo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                onClick={() => instance.logout()}
              >
                Logg ut
              </Link>
              <a
                href="https://www.dlf.no/medlemssider/"
                className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
              >
                Logg inn på medlemsside
              </a>
              <a
                href="mailto: firmapost@dlf.no"
                className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
              >
                Kontakt DLF
              </a>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default NavBarSignedIn
