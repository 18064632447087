import * as CSS from "csstype";

export const CenteringStyle: CSS.Properties = {
    display: "flex",
    justifyContent: "center",
    justifyItems: "center",
    textAlign: "left",
    marginBottom: "1.5rem"
}

export const ColumnsWrapper: CSS.Properties = {
    display: "flex",
    flexDirection: "row",
    textAlign: "left",
    marginBottom: "1.5rem"
}

export const ColumnsContent: CSS.Properties = {
    display: "flex",
    flexDirection: "column",
    padding: "2rem"
}

export const SaveButtonStyle: CSS.Properties = {
    borderRadius: "25px",
    // borderColor: "red",
    // color: "red",
    backgroundColor: "#2047f5",
    height: "2.5rem",
    maxWidth: "5rem"
}

export const LoginStyle: CSS.Properties = {
    display: "flex",
    justifyContent: "center",
    justifyItems: "center",
    flexDirection: "column",
    maxWidth: "10rem"
}

export const RowStyle: CSS.Properties = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    padding: "10px",
    textAlign: "left"
}

export const StylingFooter: CSS.Properties = {
    backgroundColor: "#bcd9e0",
    paddingLeft: "5rem"
}

export const IconStyle: CSS.Properties = {
    height: "1.5rem",
    width: "1.5rem"
}

export const LoginButtonStyle: CSS.Properties = {
    borderRadius: "25px",
    borderColor: "red",
    color: "red",
    backgroundColor: "white",
    height: "2.5rem"
}

