import React from "react"
import Footer from "../Footers/Footer"
import { Helmet } from "react-helmet"
import NavBarSignedIn from "../Headers/NavBarSignedIn"

function MyPageLayout({ children, props }) {
  return (
    <>
      <Helmet title={props} />
      <div className="font-sans bg-gray-50">
        <NavBarSignedIn />
        {/* <Header name={props}/> */}
        <div className="min-h-screen">{children}</div>
        <Footer />
      </div>
    </>
  )
}

export default MyPageLayout
