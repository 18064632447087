// import { Link } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { Image } from "cloudinary-react"
import { StylingFooterItem } from "../Styling/Style"
import { Link } from "gatsby"
import { IconStyle, StylingFooter } from "../Styling/ComponentStyles"

// const LinkSubItemFooterDesktop = ({ url, title }) => (
//     <li>
//       <Link to={url} className={StylingFooterItem}>
//         {title}&nbsp;
//         <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" viewBox="0 0 20 20" fill="currentColor">
//           <path
//             fillRule="evenodd"
//             d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
//             clipRule="evenodd"
//           />
//         </svg>
//       </Link>
//     </li>
//   )

const ExternalLinkSubItemFooterDesktop = ({ url, title }) => (
  // <li>
  <a href={url} target="_blank" rel="noreferrer" className={StylingFooterItem}>
    {title} &nbsp;
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={IconStyle}
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  </a>
  //</li>
)

/**
 * @name: Footer
 *
 */

const navigation = {
  address: [
    { name: "DLF, Dagligvareleverandørenes forening" },
    { name: "" },
    { name: "" },
  ],
}

export default function Footer() {
  return (
    <footer className="bg-footer-800" aria-labelledby="footer-heading">
      <h2 className="sr-only">Footer</h2>
      <div className="max-w-7xl mx-auto pt-12 px-4 sm:px-2 md:px-4 lg:px-20 pb-20">
        <div className="md:grid md:grid-cols-2 xl:gap-2">
          <div className="space-y-2 lg:col-span-1">
            <h3 className="text-md font-semibold text-footer-400 tracking-wider uppercase">
              Kontakt oss:
            </h3>
            <p className="text-footer-400 text-base">
              DLF Dagligvareleverandørenes forening,
            </p>
            <p className="text-base text-footer-400">Grensen 9,</p>
            <p className="text-base text-footer-400">0159 OSLO</p>
            <p className="text-base text-footer-400 hover:text-white">
              <a href="mailto: firmapost@dlf.no">firmapost@dlf.no</a>
            </p>
            <p className="text-base text-footer-400 hover:text-white ">
              <a
                className="hover:border-b-2"
                href="https://www.dlf.no/dlfs-personvernerklaering/"
                target="_blank"
              >
                DLFs personvernerklæring
              </a>
            </p>

            {/*  */}
          </div>
          {/* <div className="pb-8 xl:grid xl:grid-cols-5 xl:gap-8">
                <div className="grid grid-cols-2 gap-8 xl:col-span-6">
                  <div className="md:grid md:grid-cols-2 md:gap-8"> */}
          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-1">
            <ExternalLinkSubItemFooterDesktop
              url="https://www.google.com/maps/place/Dagligvareleverand%C3%B8renes+forening/@59.9139005,10.7416209,17.26z/data=!4m5!3m4!1s0x46416e62b7c09b27:0xaebb79cc187c372!8m2!3d59.9138992!4d10.7434408"
              title="Se på kart"
            />
          </div>
        </div>

        {/* <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            {navigation.social.map((item) => (
              <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-300">
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div> */}
      </div>

      {/* Start footer section */}
      {/* <div className="relative max-w-full grid gap-4 lg:gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 content-center  pb-14 md:pb-48  sm:px-12 md:pl-12 md:pr-1 lg:pl-20  xl:px-36 place-content-center"> */}
      {/* 4 grid coloum */}
      {/* 
          <div className="sm:col-span-2 md:col-span-3 lg:col-span-4 py-6 xl:py-14 ">
            <Link to="/">
              <Image
                cloudName={process.env.CLOUDINARY_CLOUD_NAME}
                publicId="v1624539199/logo/DLF/dlf_logo.png"
                alt="DLF Logo"
                loading="lazy"
                className="h-10"
                secure
              /> */}
      {/* <Image
                cloudName={process.env.CLOUDINARY_CLOUD_NAME}
                publicId="v1624539199/logo/DLF/dlf_logo.png"
                alt="DLF Logo"
                loading="lazy"
                className="block lg:hidden h-28 w-auto"
                secure
              /> */}
      {/* </Link>
          </div>

          <div>
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Kontakt oss:</h3>
            <p className="text-gray-300">Dagligvareleverandørenes forening</p>
            <p className="text-gray-300">Grensen 9</p>
            <p className="text-gray-300">0159 OSLO</p>

            <div className="pt-7 pb-2">
              <ul>
                <ExternalLinkSubItemFooterDesktop
                  url="https://www.google.com/maps/place/Dagligvareleverand%C3%B8renes+forening/@59.9139005,10.7416209,17.26z/data=!4m5!3m4!1s0x46416e62b7c09b27:0xaebb79cc187c372!8m2!3d59.9138992!4d10.7434408"
                  title="Se på kart"
                />
              </ul>
            </div>
          </div>

          <div>
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Telefon:</h3>
            <p className="text-gray-300">23 00 32 10</p>

            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">E-post</h3>
            <p className="text-gray-300">firmapost@dlf.no</p>
          </div> */}

      {/* <div className=" pt-12 md:pt-0 ">
            <ul>
              <LinkSubItemFooterDesktop url="/om-dlf" title="Om DLF" />
              <LinkSubItemFooterDesktop url="/produktgrupper" title="Våre produkter" />
              <LinkSubItemFooterDesktop url="/for-produsenter" title="For produsenter" />
              <LinkSubItemFooterDesktop url="/tema" title="Tema" />
              <LinkSubItemFooterDesktop url="/aktuelt" title="Aktuelt" />
            </ul>
          </div> */}

      {/* Last column, social links */}
      {/* <div className=" pt-12 md:pt-0 ">
            <ul>
              <ExternalLinkSubItemFooterDesktop
                url="https://www.linkedin.com/company/dlf-sa/"
                title="LinkedIN"
              />
              <ExternalLinkSubItemFooterDesktop url="https://facebook.com/dlf" title="Facebook" />
              <ExternalLinkSubItemFooterDesktop url="https://www.instagram.com/dlf_sa/" title="Instagram" />
            </ul>
          </div> */}
      {/* </div> */}
    </footer>
  )
}
