export const StylingFooterItem =
  "block py-1 text-body18 text-white hover:underline hover:text-squash-500 text-footer-400"

export const MinSideButton =
  "bg-transparent text-squash-500 hover:bg-tomat-500 hover:text-white font-semibold hover:text-tomat-500 hover:underline py-2 px-4 border border-tomat-500 hover:border-transparent rounded-full"

export const BadgeStyleTransparentRed =
  "text-tomat-500 hover:text-white px-3 rounded-md text-base font-axiformaSemibold"

export const BannerStyle = "height-200 text-tomat-500"

export const SaveButton =
  "bg-transparent text-squash-500 hover:bg-tomat-500 hover:text-white font-semibold hover:text-lysgronn30-500 hover:underline py-2 px-4 border border-lysgronn30-500 hover:border-transparent rounded-full"
